import {
  AdjustmentType,
  AdjustmentUnit,
  OrderItem,
  OrderItemStatus,
  OrderPaymentStatus,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import {
  getAdjustmentValue,
  getNetSubTotal,
  getPaymentAdjustmentValue,
} from '@oolio-group/order-helper';
import React, { useEffect, useMemo, useRef, Ref } from 'react';
import { FlatList, Image, StyleSheet, Text, View } from 'react-native';
import { ScreenName } from '../../common/enum';
import theme from '../../common/theme';
import { Background } from '../../component/Background/Background';
import CartItem from '../../component/CartItem/CartItem';
import { useCart } from '../../hooks/cart/CartProvider';
import useBehaviorSubjectState from '../../hooks/rxjs/useSubjectState';
import { useCartSetActiveScreen } from '../../hooks/useCartNavigationHelper/useCartSwitcherEffect';
import { sessionSubject } from '../../state/sessionObservable';
import { usePreviousValue } from '@oolio-group/hooks';

const renderItem = ({ item }: { item: OrderItem; index: number }) => {
  return <CartItem item={item} />;
};

const CartScreen: React.FC = () => {
  const { formatCurrency } = useCurrency();
  const { translate } = useTranslation();

  useCartSetActiveScreen(ScreenName.CART);
  const { value: session } = useBehaviorSubjectState(sessionSubject);
  const { order } = useCart();
  const flatListRef = useRef<FlatList<OrderItem>>(null);
  const totalTax = order.taxes?.reduce((sum, tax) => tax.amount + sum, 0);
  const discounts = order?.adjustments?.filter(
    adjustment => adjustment.adjustmentType == AdjustmentType.DISCOUNT,
  );
  const nonPaymentSurcharges = order.adjustments?.filter(
    adjustment =>
      adjustment.adjustmentType == AdjustmentType.SURCHARGE &&
      !adjustment.allowOnPaymentType,
  );

  const paymentSurcharges = order?.adjustments?.filter(
    surcharge => surcharge.allowOnPaymentType,
  );

  const totalPaymentSurcharge = useMemo(
    () =>
      (order?.payments || [])
        .filter(x => x.status === OrderPaymentStatus.COMPLETE)
        .reduce((acc, x) => acc + Number(x?.paymentSurcharge ?? 0), 0),
    [order?.payments],
  );

  const nonPaymentSurchargeAmount =
    (order?.surchargeAmount || 0) - totalPaymentSurcharge;

  const paymentValue = getPaymentAdjustmentValue(
    order.subTotal + nonPaymentSurchargeAmount || 0,
    order.adjustments || [],
  );

  const netSubTotal = useMemo(
    () => getNetSubTotal(order?.subTotal || 0, order?.adjustments),
    [order.subTotal, order.adjustments],
  );

  const Discount = () => {
    return (
      <>
        {(discounts || []).map(discount => {
          const discountFormatted =
            (discount.name && discount.name !== ''
              ? `${discount.name} `
              : 'Discount ') +
            '(' +
            String(
              discount.adjustmentUnit === AdjustmentUnit.FLAT
                ? `${formatCurrency(discount.amount)}`
                : `${discount.amount}%`,
            ) +
            ')';
          return (
            <View key={discount.id} style={styles.rowStyle}>
              <Text style={styles.smallText}>{discountFormatted}</Text>
              <Text style={styles.smallText} testID="order-discount">
                {formatCurrency(
                  getAdjustmentValue(
                    discount.doNotIncludeInSalesAmount
                      ? netSubTotal
                      : order.subTotal,
                    [discount],
                  ),
                )}
              </Text>
            </View>
          );
        })}
      </>
    );
  };

  const Surcharge = () => {
    return (
      <>
        {(nonPaymentSurcharges || []).map(surcharge => {
          const surchargeFormatted =
            (surcharge.name ?? 'Surcharge') +
            '(' +
            String(
              surcharge.adjustmentUnit === AdjustmentUnit.PERCENTAGE
                ? `${surcharge.amount}%`
                : `${formatCurrency(surcharge.amount)}`,
            ) +
            ')';
          return (
            <View key={surcharge.id} style={styles.rowStyle}>
              <Text style={styles.smallText}>{surchargeFormatted}</Text>
              <Text style={styles.smallText} testID="order-surcharge">
                {formatCurrency(
                  getAdjustmentValue(
                    surcharge.doNotIncludeInSalesAmount
                      ? netSubTotal
                      : order.subTotal,
                    [surcharge],
                  ),
                )}
              </Text>
            </View>
          );
        })}
      </>
    );
  };

  const paymentSurchargeFormatted = useMemo(
    () =>
      (paymentSurcharges || [])
        .map(
          paymentSurcharge =>
            (paymentSurcharge.name ?? 'Surcharge') +
            '(' +
            String(
              paymentSurcharge.adjustmentUnit === AdjustmentUnit.PERCENTAGE
                ? `${paymentSurcharge.amount}%`
                : `${formatCurrency(paymentSurcharge.amount)}`,
            ) +
            ')',
        )
        .join(', '),
    [paymentSurcharges, formatCurrency],
  );

  const displayableOrderItems = useMemo(
    () =>
      order?.orderItems?.filter(
        item =>
          ![OrderItemStatus.CANCELLED, OrderItemStatus.VOID].includes(
            item.status,
          ),
      ),
    [order],
  );

  const scrollToEnd = () => {
    setTimeout(() => {
      flatListRef && flatListRef.current?.scrollToEnd();
    }, 100);
  };

  const previousLength = usePreviousValue(displayableOrderItems?.length);

  useEffect(() => {
    if (
      displayableOrderItems &&
      previousLength &&
      displayableOrderItems?.length > previousLength
    ) {
      scrollToEnd();
    }
  }, [displayableOrderItems, previousLength]);

  return (
    <Background session={session}>
      <View style={styles.container}>
        <View style={styles.part2}>
          <View style={styles.innerView}>
            <View>
              {session.customerDisplay?.connectedPosDevice?.deviceProfile
                ?.orgLogo ? (
                <Image
                  source={{
                    uri: session.customerDisplay?.connectedPosDevice
                      ?.deviceProfile?.orgLogo,
                  }}
                  style={styles.orgLogo}
                />
              ) : (
                <Text style={styles.logoText}>
                  {session?.currentStore?.name}
                </Text>
              )}
            </View>
            <View style={styles.bottomLogo}>
              <Text style={styles.bottomText}>
                {translate('cdsCartScreen.poweredBy')}
              </Text>
              <Image
                source={{ uri: theme.tillWhiteLogo }}
                style={styles.logo}
              />
            </View>
          </View>
        </View>
        <View style={styles.part1}>
          <View style={styles.topView}>
            <View style={styles.titleView}>
              <Text style={styles.largeText}>
                {order.customer
                  ? translate('cdsCartScreen.greetingUser', {
                      firstName: order.customer.firstName,
                      lastName: order.customer.lastName,
                    })
                  : !!order.orderName
                  ? order.orderName
                  : translate('cdsCartScreen.yourOrder')}
              </Text>
            </View>
            {!!order.orderNote && (
              <View style={styles.noteView}>
                <Text style={styles.noteText} testID="order-note">
                  {order.orderNote.trim()}
                </Text>
              </View>
            )}
            <FlatList
              ref={flatListRef as Ref<FlatList<OrderItem>>}
              data={displayableOrderItems}
              renderItem={renderItem}
            />
          </View>
          <View style={styles.bottomView}>
            <View style={styles.rowStyle}>
              <Text style={styles.smallText}>
                {translate('payment.subTotal')}
              </Text>
              <Text style={styles.smallText} testID="order-subTotal">
                {formatCurrency(order.subTotal ?? 0)}
              </Text>
            </View>
            {typeof totalTax === 'number' && totalTax > 0 && (
              <View style={styles.rowStyle}>
                <Text style={styles.smallText}>
                  {translate('payment.totalTaxes')}
                </Text>
                <Text style={styles.smallText} testID="order-taxes">
                  {formatCurrency(totalTax)}
                </Text>
              </View>
            )}
            {typeof order.discountAmount === 'number' &&
              order.discountAmount > 0 && <Discount />}
            {typeof nonPaymentSurchargeAmount === 'number' &&
              nonPaymentSurchargeAmount > 0 && <Surcharge />}
            {typeof paymentValue === 'number' && paymentValue > 0 && (
              <View style={styles.rowStyle}>
                <Text style={styles.smallText}>
                  {paymentSurchargeFormatted}
                </Text>
                <Text style={styles.smallText} testID="order-paymentValue">
                  {formatCurrency(paymentValue)}
                </Text>
              </View>
            )}
            <View style={styles.rowStyle}>
              <Text style={styles.largeText}>
                {translate('payment.totalDue')}
              </Text>
              <Text style={styles.largeText} testID="order-totalPaymentAmount">
                {formatCurrency(order.amountDue ?? 0)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Background>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    padding: 20,
    maxHeight: theme.deviceHeight,
  },
  part1: {
    flex: 1,
    margin: theme.spacing.small,
    overflow: 'hidden',
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.white,
  },
  part2: {
    flex: 1,
  },
  image: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  topView: {
    flex: 1,
    paddingLeft: theme.spacing.large,
    paddingRight: theme.spacing.medium,
  },
  titleView: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
    paddingBottom: theme.spacing.large,
    paddingTop: theme.spacing.large,
    alignItems: 'center',
  },
  bottomView: {
    paddingVertical: theme.spacing.medium,
    paddingHorizontal: theme.spacing.large,
    borderWidth: 1,
    backgroundColor: theme.colors.grey2,
    borderColor: theme.colors.grey3,
  },
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  smallText: {
    lineHeight: 22,
    fontWeight: '500',
    color: theme.colors.grey5,
    fontSize: theme.fontSize.medium,
  },
  largeText: {
    lineHeight: 40,
    fontWeight: '600',
    color: theme.colors.black,
    fontSize: theme.fontSize.large,
  },
  noteText: {
    lineHeight: 22,
    fontWeight: '500',
    color: theme.colors.black,
    fontSize: theme.fontSize.medium,
  },
  innerView: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: theme.spacing.large,
    justifyContent: 'center',
  },
  bottomLogo: {
    position: 'absolute',
    bottom: 30,
    opacity: 0.3,
    alignItems: 'center',
  },
  logo: {
    width: 200,
    height: 30,
    resizeMode: 'contain',
  },
  orgLogo: {
    width: theme.moderateScale(140),
    height: theme.moderateScale(140),
    resizeMode: 'contain',
  },
  logoText: {
    fontSize: 38,
    lineHeight: 38,
    fontWeight: 'bold',
    color: theme.colors.white,
  },
  bottomText: {
    marginBottom: 10,
    alignSelf: 'center',
    color: theme.colors.white,
    fontSize: theme.fontSize.xs,
  },
  noteView: {
    backgroundColor: theme.colors.yellowLight,
    paddingVertical: theme.spacing.medium,
    paddingHorizontal: theme.spacing.large,
    marginVertical: theme.spacing.small,
    borderRadius: theme.spacing.small,
    alignItems: 'center',
  },
});

export default CartScreen;
