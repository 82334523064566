import { OrderItem, OrderItemComboProduct } from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import {
  computeOrderItemTotal,
  getAdjustmentLabel,
} from '@oolio-group/order-helper';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import theme from '../../common/theme';

export interface ItemProps {
  item: OrderItem;
}

const CartItem: React.FC<ItemProps> = ({ item }) => {
  const { formatCurrency } = useCurrency();
  const { translate } = useTranslation();

  const renderModifiers = (orderItem: OrderItem | OrderItemComboProduct) => {
    return orderItem.modifiers?.map(modifier => {
      const sum =
        modifier.unitPrice *
        (modifier?.quantity * (orderItem.quantity ?? 1) * item.quantity);
      return (
        <View style={styles.rowStyle} key={modifier.id}>
          <Text style={styles.smallText} testID="order-item-modifiers-name">
            {modifier.quantity ?? 1} x {modifier.name}
          </Text>
          <Text style={styles.smallText} testID="order-item-modifiers-price">
            {formatCurrency(sum)}
          </Text>
        </View>
      );
    });
  };

  const renderComboItem = () => {
    return item?.comboItems?.map(combo => {
      const sum =
        (combo.amount ?? 0) * ((combo?.quantity ?? 1) * (item.quantity ?? 1));
      return (
        <>
          <View style={styles.rowStyle} key={combo.id}>
            <Text style={styles.comboName} testID="order-item-combo">
              {combo?.quantity ?? 1} x {combo.product?.name}
            </Text>
            <Text style={styles.smallText} testID="order-item-modifiers-price">
              {formatCurrency(sum)}
            </Text>
          </View>
          {renderModifiers(combo)}
        </>
      );
    });
  };

  const renderAdjustments = (
    adjustmentAmount: number,
    label: string,
    testID: string,
  ) => {
    return (
      typeof adjustmentAmount === 'number' &&
      adjustmentAmount > 0 && (
        <View style={styles.rowStyle}>
          <Text style={styles.smallText}>{label}</Text>
          <Text style={styles.smallText} testID={testID}>
            {formatCurrency(adjustmentAmount)}
          </Text>
        </View>
      )
    );
  };

  return (
    <View style={styles.itemContainer}>
      <View style={styles.rowStyle}>
        <Text style={styles.largeText} testID="order-item-name-quantity">
          {`${item?.quantity > 1 ? ` ${item?.quantity} x ` : ''}${
            !!item.variant ? item.variant?.name : item.product?.name
          }`}
        </Text>
        <Text style={styles.largeText} testID="order-item-price">
          {formatCurrency(computeOrderItemTotal(item))}
        </Text>
      </View>
      {!!item.variant && (
        <View style={styles.rowStyle}>
          <Text style={styles.smallText} testID="order-item-variant">
            {item.product.name.replace(`${item.variant.name}-`, '')}
          </Text>
        </View>
      )}
      {renderModifiers(item)}
      <View>
        {renderComboItem()}
        {!!item.savedAmount && item.savedAmount > 0 && (
          <Text style={styles.messageSave}>
            {translate('cdsCartScreen.saveMessage', {
              savedAmount: formatCurrency(item.savedAmount),
              productName: item.product?.name,
            })}
          </Text>
        )}
      </View>
      {renderAdjustments(
        item.discountAmount,
        getAdjustmentLabel(item.adjustments?.[0]) ||
          translate('order.discount'),
        'order-item-discount',
      )}
      {renderAdjustments(
        item.surchargeAmount || 0,
        getAdjustmentLabel(item.adjustments?.[0]) ||
          translate('order.surcharge'),
        'order-item-surcharge',
      )}
      {!!item.notes && (
        <Text style={styles.smallText} testID="order-item-notes">
          {translate('cdsCartScreen.itemNote', { note: item.notes.trim() })}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    paddingVertical: theme.spacing.medium,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.grey3,
    paddingRight: theme.spacing.medium,
  },
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  smallText: {
    fontSize: theme.fontSize.small,
    color: theme.colors.grey6,
    lineHeight: 22,
  },
  largeText: {
    fontWeight: '600',
    fontSize: theme.fontSize.large,
    color: theme.colors.black,
    lineHeight: 40,
  },
  comboName: {
    fontWeight: '400',
    fontSize: theme.fontSize.small,
    color: theme.colors.black,
    lineHeight: 22,
  },
  messageSave: {
    fontWeight: '600',
    fontSize: theme.fontSize.small,
    color: theme.colors.blue,
    marginTop: 10,
  },
});

export default CartItem;
